import React from "react";
import styled from "styled-components";
import Lightbox from "react-awesome-lightbox";
import { useState } from "react";

const ImageComponentView = (props) => {
  const [showImage, setShowImage] = useState(false);
  return showImage ? (
    <Lightbox
      image={props.url}
      title={props.name}
      onClose={() => {
        setShowImage(false);
      }}
    />
  ) : (
    <Container>
      <Contents>
        <img
          src={props.url}
          alt=""
          onClick={() => {
            setShowImage(true);
          }}
        />
        <i className="fas fa-spinner fa-spin"></i>

        <p>{props.name}</p>
      </Contents>
    </Container>
  );
};

const Container = styled.div`
  max-width: 200px;
  height: 410px;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  position: relative;
  align-items: center;

  img {
    max-width: 200px;
    z-index: 10;
    cursor: pointer;
  }

  i {
    position: absolute;
    top: 200px;
    /* z-index: -1; */
  }

  p {
    font-size: 12px;
    margin-top: 20px;
  }
`;

export default ImageComponentView;
