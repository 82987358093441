import React from "react";
import styled from "styled-components";
import ImageContainer from "components/ImageDashboard/ImageComponent";
import VisitDash from "components/VisitDash";
const ImageDashboardView = (props) => {
  return (
    <Container>
      <FilterBox>
        {props.filters.map((e) => {
          return (
            <button
              type="button"
              className={
                // eslint-disable-next-line eqeqeq
                props.filter == e
                  ? "btn btn-block btn-primary"
                  : "btn btn-block btn-default"
              }
              onClick={(e) => {
                e.preventDefault();
                props.setFilterState(e.target.innerHTML);
              }}
            >
              {e}
            </button>
          );
        })}
      </FilterBox>
      <Contents>
        {props.filter === "Dashboard" ? (
          <VisitDash path={props.path} />
        ) : (
          Object.keys(props.imageData).map((e) => {
            return <ImageContainer id={e} name={e} path={props.imageData[e]} />;
          })
        )}
      </Contents>
    </Container>
  );
};

const Container = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  flex-direction: column;
  align-items: center;
`;

const FilterBox = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: baseline;
  height: 40px;
  margin-left: auto;
  margin-right: 48px;
  position: relative;
  /* width: 100%; */
  button {
    padding: 4px 18px;
    margin: 0 3px;
    :nth-child(1) {
      /* position: absolute; */
      left: 0;
    }
  }
  line-height: 0px;
`;

const Contents = styled.div`
  display: grid;
  grid-gap: 10px;
  width: 90%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  justify-content: center;
  align-items: center;
`;

export default ImageDashboardView;
