import React from "react";
import { enGB } from "date-fns/locale";
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { useState } from "react";
import styled from "styled-components";

const DatePickerComponent = (props) => {
  return (
    <Container>
      <DateRangePicker
        startDate={props.startDate}
        endDate={props.endDate}
        onStartDateChange={props.setStartDate}
        onEndDateChange={props.setEndDate}
        minimumLength={0}
        format="dd MMM yyyy"
        locale={enGB}
      >
        {({ startDateInputProps, endDateInputProps, focus }) => (
          <div className="date-range">
            <input
              className={"input" + (focus === START_DATE ? " -focused" : "")}
              {...startDateInputProps}
              placeholder="Start date"
            />
            {/* <span >&ensp; <i className="fa fa-chevron-left"></i> <i className="fa fa-chevron-right"></i> &ensp;</span> */}
            <span style={{fontSize:"20px"}} >&ensp; to &ensp;</span>
            <input
              className={"input" + (focus === END_DATE ? " -focused" : "")}
              {...endDateInputProps}
              placeholder="End date"
            />
          </div>
        )}
      </DateRangePicker>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  .date-range_arrow {
    font-size: 32px;
    color: #0e3254;
    font-weight: 100;
  }
  input {
    -webkit-appearance: none;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #d3dde6;
    color: #0e3254;
    /* display: block; */
    font-family: inherit;
    font-size: inherit;
    height: 42px;
    outline: none;
    padding: 0 12px;
    transition: border-color 0.2s;
  }
  input:focus {
    border-color: #438cd2;
  }
  .nice-dates-popover {
    border-radius: 6px;
    width: 340px;
    right: 340px;
    /* justify-self: center; */
  }
`;

export default DatePickerComponent;
