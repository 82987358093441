import React from 'react';
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";
import IFrame from "components/IFrame";

export const HomeView = (props) => {
  return (
    <div className="wrapper">
      {/* <Navbar /> */}
      <Sidebar />
      <IFrame />
      <footer
        className="main-footer"
        style={{
          position: "fixed",
          zIndex: "15",
          bottom: 0,
          width: "100%",
          backgroundColor: "#f4f6f9"
        }}
      >
        <strong>
          Copyright © 2023{" "}
          <a href="https://www.adiuvodiagnostics.com/">
            adiuvodiagnostics.com{"  "}
          </a>
        </strong>
        All rights reserved.
        {/* <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 1.0.0
        </div> */}
      </footer>
    </div>
  );
};
