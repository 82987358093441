import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataStore from "services/facade/dataStore";

const initialState = {
  PID_: null,
  woundDataPoint: {
    isFetching: false,
    isError: false,
  },
  woundMetrics: {
    isFetching: false,
    isError: false,
  },
  imageWL: {
    isFetching: false,
    isError: false,
  },
  imageWLL: {
    isFetching: false,
    isError: false,
  },
  thumbnail:{
    isFetching : false,
    isError: true
  },
  data: {},
};

const mDataStore = new DataStore();

export const fetchImageAndURL = createAsyncThunk(
  "data/fetchImageAndURL",
  ({ PID, VID, searchStr, dateString}, thunkAPI) => {
    return new Promise((resolve, reject) => {
      mDataStore
        .fetchImagePath(PID, VID, searchStr, dateString)
        .then((path) => {
          console.log(path);
          if(!path){
            mDataStore.fetchImagePathFromProcessed(PID, VID, searchStr, dateString).then((path) => {
              mDataStore.fetchImageURL(path).then((url) => {
                resolve({ PID , url });
              });
            });
          }
          mDataStore.fetchImageURL(path).then((url) => {
            resolve({ PID, url });
          });
        })
        .catch((err) => {
          reject();
        });
    });
  }
);

export const fetchLImageAndURL = createAsyncThunk(
  "data/fetchLImageAndURL",
  ({ PID, VID, searchStr,dateString }, thunkAPI) => {
    return new Promise((resolve, reject) => {
      mDataStore
        .fetchImagePath(PID, VID, searchStr,dateString)
        .then((path) => {
          console.log(path);
          mDataStore.fetchImageURL(path).then((url) => {
            resolve({ PID, url });
          });
        })
        .catch((err) => {
          reject();
        });
    });
  }
);

export const createThumbnail = createAsyncThunk(
  "data/createThumbnail",
  ({ PID, VID, searchStr, dateString }, thunkAPI) => {
    
    return new Promise((resolve, reject) => {
          mDataStore
        .fetchImagePath(PID, VID, searchStr, dateString)
        .then((path) => {
          console.log(path);
          mDataStore.fetchImageURL(path).then((url) => {
            resolve(url.url);
          });
        })
        .catch((err) => {
          reject();
        }); 
      })
   
  }
  
);



export const fetchWoundDataPoint = createAsyncThunk(
  "data/fetchWoundDataPoint",
  ({ PID }, thunkAPI) => {
    return new Promise((resolve, reject) => {
      console.log(PID);
      mDataStore
        .fetchWoundDataPoint(PID)
        .then((data) => {
          resolve({ PID, data });
        })
        .catch((err) => {
          reject();
        });
    });
  }
);

export const fetchWoundMetrics = createAsyncThunk(
  "data/fetchWoundMetrics",
  ({ PID }, thunkAPI) => {
    return new Promise((resolve, reject) => {
      console.log(PID);
      mDataStore
        .fetchWoundMetrics(PID)
        .then((data) => {
          resolve({ PID, data });
        })
        .catch((err) => {
          reject();
        });
    });
  }
);

const woundDashSlice = createSlice({
  name: "woundDashState",
  initialState,
  reducers: {
    refreshData: (state) => {
      state = initialState;
    },
    setPID: (state, { payload }) => {
      state.PID_ = payload;
    },
  },
  extraReducers: {
    [fetchWoundDataPoint.pending]: (state) => {
      state.woundDataPoint.isFetching = true;
    },
    [fetchWoundDataPoint.rejected]: (state) => {
      state.woundDataPoint.isError = true;
    },
    [fetchWoundDataPoint.fulfilled]: (state, { payload }) => {
      //   state.woundDataPoint.value = payload;
      state.data[payload.PID] = state.data[payload.PID]
        ? state.data[payload.PID]
        : {};
      state.data[payload.PID]["woundDataPoint"] = payload.data;
      state.woundDataPoint.isFetching = false;
      state.woundDataPoint.isError = false;
    },
    [fetchWoundMetrics.pending]: (state) => {
      state.woundMetrics.isFetching = true;
    },
    [fetchWoundMetrics.rejected]: (state) => {
      state.woundMetrics.isError = true;
    },
    [fetchWoundMetrics.fulfilled]: (state, { payload }) => {
      //   state.woundMetrics.value = payload;
      state.data[payload.PID] = state.data[payload.PID]
        ? state.data[payload.PID]
        : {};
      state.data[payload.PID]["woundMetrics"] = payload.data;
      // let processedData = [0, 0, 0];
      // Object.keys(payload.data).forEach((e) => {});

      state.woundMetrics.isFetching = false;
      state.woundMetrics.isError = false;
    },
    [fetchImageAndURL.pending]: (state) => {
      state.imageWL.isFetching = true;
    },
    [fetchImageAndURL.rejected]: (state) => {
      state.imageWL.isError = true;
    },
    [fetchImageAndURL.fulfilled]: (state, { payload }) => {
      //   state.imageWL.path = payload.path;
      //   state.imageWL.url = payload.url;
      state.data[payload.PID] = state.data[payload.PID]
        ? state.data[payload.PID]
        : {};
      console.log("I");

      state.data[payload.PID]["imageWL"] = payload.url.url;
      state.url = payload.url.url

      state.imageWL.isFetching = false;
      state.imageWL.isError = false;
    },
    [fetchLImageAndURL.pending]: (state) => {
      state.imageWLL.isFetching = true;
    },
    [fetchLImageAndURL.rejected]: (state) => {
      state.imageWLL.isError = true;
    },
    [fetchLImageAndURL.fulfilled]: (state, { payload }) => {
      //   state.imageWL.path = payload.path;
      //   state.imageWL.url = payload.url;
      state.data[payload.PID] = state.data[payload.PID]
        ? state.data[payload.PID]
        : {};
      console.log("lI");
      state.data[payload.PID]["imageWLL"] = payload.url.url;

      state.imageWLL.isFetching = false;
      state.imageWLL.isError = false;
    },
    [createThumbnail.fulfilled] : (state, {payload}) =>{
        state.thumbnail.data = payload
        state.thumbnail.isFetching = false;
        state.thumbnail.isError = false;
    },
    [createThumbnail.pending]: (state) =>{
      state.thumbnail.isFetching = true
    },
    [createThumbnail.rejected] : (state) =>{
      state.thumbnail.isError = true
    }
  },
});

export const { refreshData, setPID } = woundDashSlice.actions;

export const selectWoundDashState = (state) => state.woundDashState;

export default woundDashSlice.reducer;
