import styled from "@emotion/styled";
import { Download, NotificationsActiveRounded } from "@mui/icons-material";
import { Badge, Button, Chip, Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { firebaseGetReminderList } from "services/firebase/firebaseStorage";
import notify from "../../../asset/lottie/Notify.json";
import noReminders from "../../../asset/lottie/noReminders.json";
import Lottie from "react-lottie";
import { CSVLink } from "react-csv";

function Reminders({ isReminderOpen, setTodayReminderCount, handleReminderOpen }) {
  const [reminderList, setReminderList] = useState(null);
  const getTodayReminderKey = () => {
    let currentDate = new Date();
    let day = currentDate.getDate();
    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();

    // Add a leading zero to the day and month if they're less than 10
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    let dateFormatted = day.toString() + month.toString() + year.toString();
    return `date_${dateFormatted}`

  }
  useEffect(() => {
    firebaseGetReminderList()
      .then((data) => {
        if (!data) {
          setReminderList({});
        } else {
          setReminderList({[getTodayReminderKey()]:data});
          console.log(getTodayReminderKey())
          setTodayReminderCount(Object.keys(data).length)
        }
      })
      .catch((err) => {
        console.error(err);
      });

  }, []);

  const CustomDialog = styled(Dialog)(({ theme }) => ({
    ".MuiPaper-root": {
      borderRadius: "25px",
      width: "100%",
      maxHeight:"90vh",
      overflow: "auto"
    },
  }));

  const getRemindersDate = (dateString) => {
    let dateArr = dateString.split("_").at(-1).split("");
    if (dateArr.length === 8) {
      return new Date(
        `${dateArr[2]}${dateArr[3]}/${dateArr[0]}${dateArr[1]}/${dateArr[4]}${dateArr[5]}${dateArr[6]}${dateArr[7]}`
      );
    } else {
      throw new Error(
        `Invalid Reminder Date ${dateString}, ${dateArr.join(",")} - ${
          dateArr.length
        }`
      );
    }
  };

  const ReminderTable = ({data}) =>{ 
    console.log(data)
  return <> 
  <div className="d-flex">
 <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: notify,
              }}
              height="100px"
              width="100px"
              isClickToPauseDisabled={true}
              style={{
                cursor: "unset",
              }}
            />
            <h3 style={{ fontFamily: "poppins", textAlign:"center", textTransform:"uppercase"}}>Reminders</h3>
  </div>
  
  <Table>
    <TableHead>
        <TableRow>
            <TableCell>PID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>No. visits</TableCell>
            <TableCell>Last Visit</TableCell>
            <TableCell>Next Visit</TableCell>
        </TableRow>
    </TableHead>
    <TableBody>
        {
            data.map(row=><TableRow>
                <TableCell>{row.pid}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.visits}</TableCell>
                <TableCell>{row.lastVisitDate}</TableCell>
                <TableCell>{row.nextVisitDate}</TableCell>
            </TableRow>)
        }
    </TableBody>
  </Table>
  </>}

  const getDate = (dateString) => {
    let dateArr = dateString.split("_").at(-1).split("");
    if (dateArr.length >= 8) {
      return new Date(
        `${dateArr[2]}${dateArr[3]}/${dateArr[0]}${dateArr[1]}/${dateArr[4]}${dateArr[5]}${dateArr[6]}${dateArr[7]}`
      );
    } else {
      throw new Error(
        `Invalid Reminder Date ${dateString}, ${dateArr.join(",")} - ${
          dateArr.length
        }`
      );
    }
  };

  const dayCount = (startDate, endDate) => {
    // Convert both dates to milliseconds
    let startTime = new Date(startDate).getTime();
    let endTime = new Date(endDate).getTime();

    // Calculate the difference in milliseconds
    let difference = Math.abs(endTime - startTime);

    // Convert the difference to days and return
    return Math.ceil(difference / (1000 * 60 * 60 * 24));
  };

  const isToday = (dateString) => (
        new Date(dateString).toLocaleDateString("en-IN") ===
        new Date().toLocaleDateString("en-IN")
      )

  const nthNumber = (number) => {
    if (number > 3 && number < 21) return `${number}th`;
    switch (number % 10) {
      case 1:
        return `${number}st`;
      case 2:
        return `${number}nd`;
      case 3:
        return `${number}rd`;
      default:
        return `${number}th`;
    }
  };

  const getTableContent = (reminderData)=>{
    let tableData = []
    Object.keys(reminderData).filter(
        (reminderDate) =>
          getRemindersDate(reminderDate) >=
          new Date(new Date().toDateString())
      )
      .sort((a, b) => getRemindersDate(a) - getRemindersDate(b))
      .forEach((reminderDate)=>{
        Object.keys(reminderData[reminderDate])
            .forEach(pid=>{
                tableData.push({
                    pid: pid,
                    name: reminderData[reminderDate][pid].name || 'Unkown',
                    visits :`${ reminderData[reminderDate][pid].lastVisitNo}`,
                    lastVisitDate: getDate(reminderData[reminderDate][pid].lastVisitDate).toLocaleDateString('en-IN'),
                    nextVisitDate : getDate(reminderData[reminderDate][pid].nextVisitDate).toLocaleDateString('en-IN')
                })
            })
      })
      console.log(tableData)
      return tableData
    }

  return (
    reminderList && (
      <>
        <CustomDialog
          open={isReminderOpen}
          onClose={handleReminderOpen}
          sx={{ padding: "2%", width: "100%" }}
        ><DialogTitle>
              <div className="row"
              style={{
                justifyContent:"flex-start",
                alignItems:"center"
              }}
              >
                <div className="col-3">
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: notify,
                        }}
                        height="100px"
                        width="100px"
                        isClickToPauseDisabled={true}
                        style={{
                            cursor: "unset",
                        }}
                    />
                </div>
                <div className="col">
                    <h3 style={{ fontFamily: "poppins", textAlign:"center", textTransform:"uppercase"}}>Reminders</h3>
                </div>
                <div className="col">
                     <div className="text-right">
                        <CSVLink
                            data={getTableContent(reminderList)}
                            filename={`Illuminate_Reminder_list_${getTodayReminderKey().split("_").at(-1)}`}
                            asyncOnClick = {true}
                        >
                        <Button
                            variant="outlined" 
                            color="primary" 
                            startIcon = {<Download />}
                            title="Download as CSV"
                            style={{
                                borderRadius:"50px",
                                marginRight:"-15px",
                                marginTop: "-80px"
                            }}
                        >
                            Download
                        </Button>
                        </CSVLink>
                    </div>
                </div>
            </div>
          </DialogTitle>

          {Object.keys(reminderList).filter(
            (reminderDate) =>
              getRemindersDate(reminderDate) >=
              new Date(new Date().toDateString())
         ).length > 0 ? (
            //  <ReminderTable  data={getTableContent(reminderList)}  />
            <DialogContent
              sx={{
                padding: "5% 2%",
              }}
            >
             
              {Object.keys(reminderList)
                .filter(
                  (reminderDate) =>
                    getRemindersDate(reminderDate) >=
                    new Date(new Date().toDateString())
                )
                .sort((a, b) => getRemindersDate(a) - getRemindersDate(b))
                .map((reminderDate) => (
                  <div style={{ margin: "5% 1%" }}
                    key={reminderDate}
                  >
                    {isToday(getRemindersDate(reminderDate)) ? (
                      <Badge
                        badgeContent={
                            Object.keys(reminderList[reminderDate]).length
                        }
                        color="info"
                        key={reminderDate}
                      >
                        <Chip color="error" label="Today" />
                      </Badge>
                    ) : (
                      <Badge
                        badgeContent={
                          Object.keys(reminderList[reminderDate]).length
                        }
                        color="info"
                      >
                        <Chip
                          color="primary"
                          label={getRemindersDate(reminderDate).toDateString(
                            "en-IN"
                          )}
                        />
                      </Badge>
                    )}
                    {Object.keys(reminderList[reminderDate]).map((pid) => (
                      <>
                        <div
                          className="card"
                          style={{
                            width: "100%",
                            margin: "2% 0",
                            padding: "2% 0",
                          }}
                        >
                          <div className="row justify-content-center align-items-center">
                            <div className="col-3 text-center">
                              <div style={{ margin: "8px" }}>
                                <NotificationsActiveRounded
                                  sx={{ fontSize: "36px" }}
                                  color={
                                    isToday(getRemindersDate(reminderDate))
                                      ? "error"
                                      : "primary"
                                  }
                                />
                              </div>
                            </div>

                            <div className="col">
                              <div className="row">
                                <div className="col">
                                  <h4>
                                    {reminderList[reminderDate][pid].name}{" "}
                                    <span>
                                      <Chip
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        label={pid}
                                        sx={{
                                          fontSize: "12px",
                                          width: "fit-content",
                                        }}
                                      />
                                    </span>
                                  </h4>
                                </div>
                                <div></div>
                              </div>

                              <small>
                                last visit on{" "}
                                {getDate(
                                  reminderList[reminderDate][pid]
                                    .lastVisitDate
                                ).toLocaleDateString("en-IN")}
                              </small>
                              <p>
                                Reminder for the{" "}
                                {nthNumber(
                                  reminderList[reminderDate][pid]
                                    .lastVisitNo + 1
                                )}{" "}
                                visit -{" "}
                                {isToday(getRemindersDate(reminderDate)) ? (
                                  <Chip
                                    label="Today"
                                    color="warning"
                                    variant="outlined"
                                  />
                                ) : (
                                  <Chip
                                    label={`In ${dayCount(
                                      getDate(
                                        reminderList[reminderDate][pid]
                                          .nextVisitDate
                                      ),
                                      new Date(new Date().toDateString())
                                    )} days`}
                                    variant="outlined"
                                  />
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ))} <hr/>
                  </div>
                ))}
               
            </DialogContent>
          ) : (
            <DialogContent>
              <div
                className="d-flex align-items-center"
                style={{ height: "30vh", flexDirection: "column" }}
              >
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: noReminders,
                  }}
                  height="150px"
                  width="150px"
                  isClickToPauseDisabled={true}
                  style={{
                    cursor: "unset",
                  }}
                />
                <b>No Reminders yet!</b>
              </div>
            </DialogContent>
          )}
        </CustomDialog>
      </>
    )
  );
}

export default Reminders;
