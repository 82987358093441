// import Plot from "react-plotly.js";

import React, { useLayoutEffect, useState, useEffect } from "react";
import Plotly from "plotly.js-basic-dist-min";
import createPlotlyComponent from "react-plotly.js/factory";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Filler,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  defaults,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LinearScale,
  PointElement,
  ArcElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Plot = createPlotlyComponent(Plotly);

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

const IlluminateActivtyGraph = (props) => {
  var ordered =  Object.keys(props.data)
    .sort((a, b) => {
      return new Date(a) - new Date(b);
    })

  function dateConverter(date) {
    var dateObject = new Date([
      date.toString().split("/")[0],
      date.toString().split("/")[1],
      date.toString().split("/")[2]
    ]);

    return dateObject;
  }

  const [width, height] = useWindowSize();
  useEffect(() => {
    // Plotly.Plots.resize("plotlyChart");
  }, [width, height]);

  console.log(props.data);

  const createDataset = (xArr, yArr) => {
    let data = [];

    for (let i = 0; i < xArr.length; i++) {
      console.log(xArr[i])
      data.push({
        x: xArr[i],
        y: props.data[xArr[i]]
      });
    }

    return data;
  };

  let lineXY = createDataset(ordered);

  console.log(lineXY);

  const lineData = {
    labels: lineXY.map((a, i) => i + 1),
    datasets: [
      {
        label: `Illuminate Activity`,
        data: lineXY,
        fill: true,
        backgroundColor: "rgba(95, 195, 246, 0.2)",
        borderColor: "rgba(95, 195, 246)",
        borderWidth: 2,
        tension: 0.4,
        pointHoverBorderColor: "white",
        pointHoverBackgroundColor: "#5fc3f6",
        pointBorderWidth: 3,
        pointHoverBorderWidth: 3,
        pointRadius: 6,
        pointHoverRadius: 6,
      },
    ],
  };

  const lineOptions = {
    maintainAspectRatio: false,
    responsive: true,

    plugins: {
      legend: {
        position: "top",
      },
      filler: {
        propagate: true,
      },
      tooltip: {
        // enabled: false,
        displayColors: false,
        // backgroundColor: "#000",
        intersect: true,
        yPadding: 10,
        xPadding: 10,
        caretSize: 4,
        bodyFontColor: "#5fc3f6",
        yAlign: "bottom",
        callbacks: {
          title: function (t) {
            console.log(t);
            return t[0].label;
          },
          label: function (t) {
            return `${t.dataset.label} : ${t.dataset.data[t.dataIndex].y}`;
          },
          afterBody: (t, d) => {
            console.log(t, d);

            return "Body";
          },
        },
        enabled: false,

        external: function (context) {
          // Tooltip Element
          let tooltipEl = document.getElementById("chartjs-tooltip");

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<table></table>";
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);

            let innerHtml = "<thead>";

            titleLines.forEach(function (title) {
              innerHtml += "<tr><th>" + title + "</th></tr>";
            });
            innerHtml += "</thead><tbody>";

            bodyLines.forEach(function (body, i) {
              const colors = tooltipModel.labelColors[i];
              let style = "background:" + colors.backgroundColor;
              style += "; border-color:" + colors.borderColor;
              style += "; border-width: 2px";
              const span = '<span style="' + style + '"></span>';
              innerHtml += "<tr><td>" + span + body + "</td></tr>";
            });
            innerHtml += "</tbody>";

            let tableRoot = tooltipEl.querySelector("table");
            tableRoot.innerHTML = innerHtml;
          }

          const position = context.chart.canvas.getBoundingClientRect();
          // const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = "absolute";
          tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX-75 + "px";
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY-95 + "px";
          // tooltipEl.style.font = bodyFont.string;
          tooltipEl.style.padding = "10px";
          tooltipEl.style.pointerEvents = "none";
          tooltipEl.style.backgroundColor = "rgba(255,255,255,0.4)"
          tooltipEl.style.borderRadius ="15px"
          tooltipEl.style.border = "solid 2px #5fc3f6"
          
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        // max : ordered.at(-1),
        min: ordered.at(0),
              },
    },
  };

  const TooltipLines = {
    id: "tooltipLine",
    displayColor: false,
    yAlign: "bottom",
    beforeDraw: (chart) => {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const ctx = chart.ctx;
        ctx.save();
        const activePoint = chart.tooltip._active[0];
        // console.log(chart.chartArea);

        ctx.beginPath();
        ctx.setLineDash([5, 7]);
        ctx.moveTo(activePoint.element.x, activePoint.element.y);
        ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
        ctx.lineWidth = 2;
        ctx.strokeStyle = "#5fc3f6";
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  return (
    <div className="row" style={{ display: "flex", justifyContent: "center" }}>
      <div
        className="card"
        style={{
          position: "relative",
          left: "0px",
          top: "0px",
          width: "95%",
          borderRadius: "25px",
        }}
      >
        <div className="card-header ui-sortable-handle">
          <h3 className="card-title">
            <i className="fas fa-chart-pie mr-1" />
            Illuminate Activity
          </h3>
        </div>
        {/* /.card-header */}
        <div className="card-body">
          <div className="tab-content p-0">
            {/* Morris chart - Sales */}
            <div
              className="chart tab-pane active"
              id="revenue-chart"
              style={{
                position: "relative",
                // height: "300px",
                // width: "100%",
              }}
            >
              {/* <Plot
                divId="plotlyChart"
                style={{ height: "auto", width: "100%" }}
                data={[
                  {
                    x: ordered,
                    // y: Object.values(ordered),
                    y: Object.values(props.data),

                    mode: "lines+markers",
                    // type: "scatter",
                    marker: { color: "#5fc3f6" },
                    line: { shape: "spline", smoothing: 0.7 },
                    fill: "tonexty",
                    fillcolor: "#tonexty"
                  },
                ]}
                layout={{
                  xaxis: {
                    title: "Date",
                    // type: "linear",
                    fixedrange: true,
                  },
                  yaxis: {
                    title: "Imaging sessions",
                    fixedrange: true,
                    autosize: true,
                  },
                }}
                config={{ displaylogo: false, displayModeBar: false }}
              /> */}
              <Line
                data={lineData}
                height={300}
                width={600}
                options={lineOptions}
                plugins={[TooltipLines]}
              />
            </div>
          </div>
        </div>
        {/* /.card-body */}
      </div>
    </div>
  );
};

export default IlluminateActivtyGraph;
