import AlertBox from 'components/elements/AlertBox'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { firebaseGetMissingData } from 'services/firebase/firebaseStorage'
import { Button, Dialog, DialogActions, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TableBar } from '@mui/icons-material'

function MissingDataAlert() {
    const [missingData, setMissingData] = useState(null)
    useEffect(()=>{
        firebaseGetMissingData().then(data=>{
            console.log(data)
            setMissingData(data)
        }).catch(err=>{
            console.error(err)
        })
    },[])

    const CustomDialog = styled(Dialog)(({ theme }) => ({
        '.MuiPaper-root':{
            borderRadius:"25px"
        }
      }));

    const [isOpen, setIsOpen] = useState(false)
    const handleClose = () =>{
        setIsOpen(!isOpen)
    }

  return missingData &&
  missingData.NumberOfMissingImages > 0 && 
  <>
    <AlertBox onPositive={()=>{setIsOpen(!isOpen)}} title="There are some missing data." message="Some of your patient data are missing. Try to sync them individually from the device." severity="error" />
    <CustomDialog
        open={isOpen}
        onClose={handleClose}
        sx={{padding:"2%"}}
    >
        <DialogTitle>
            These are the patient ids and visits that have to be uploaded.
        </DialogTitle>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Patient Id</TableCell>
                    <TableCell>Visit No.</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    Object.keys(missingData.MissingImages).map(pid=><TableRow key={pid}>
                        <TableCell>{`${pid.split("_")[0]}_${pid.split("_")[1]}`}</TableCell>
                        <TableCell>{pid.split("_").at(-1)}</TableCell>
                    </TableRow>)
                }
            </TableBody>
        </Table>
    </CustomDialog>
  </>
  
}

export default MissingDataAlert