import React from "react";
import IFrameView from "./IFrameView";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectDashboardState,
  changeCurrentTab,
  closeTab,
  closeAllTabs,
  closeAllTabsExcept,
  moveTab,
} from "redux/dashboardSlice";

export const IFrameContainer = () => {
  const dispatch = useDispatch();
  const { tabs, currentTab } = useSelector(selectDashboardState);
  const onTabClick = (index) => {
    dispatch(changeCurrentTab(index));
  };
  const onTabClose = (index) => {
    dispatch(closeTab(index));
  };
  const onCloseAllTabs = () => {
    dispatch(closeAllTabs());
  };
  const onCloseAllTabsExcept = (index) => {
    dispatch(closeAllTabsExcept(index));
  };
  const onMoveTab = (direction) => {
    dispatch(moveTab(direction));
  };
  console.log(tabs)
  return (
    <IFrameView
      key={tabs[currentTab]}
      tabs={tabs}
      currentTab={currentTab}
      onTabClick={onTabClick}
      onTabClose={onTabClose}
      onCloseAllTabs={onCloseAllTabs}
      onCloseAllTabsExcept={onCloseAllTabsExcept}
      onMoveTab={onMoveTab}
      max = {tabs.length-1}
    />
  );
};
