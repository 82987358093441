import union from "services/core/union";
import {
  firebaseAuthCheck,
  firebaseLogin,
  firebaseSignout,
  firebaseSetPersistence,
} from "services/firebase/firebaseAuth";
import { firebaseInit } from "services/firebase";

const AuthError = union([
  "invalidEmail",
  "wrongPassword",
  "userNotFound",
  "genericError",
]);

//"auth/invalid-email"
//"auth/wrong-password"
//"auth/user-not-found"

class AuthFacade {
  constructor() {}

  init = () => {
    return new Promise(async (resolve, reject) => {
      await firebaseInit();
      console.log("Auth initiated");
      resolve();
    });
  };

  setPersistence = async (state) => {
    await firebaseSetPersistence(state);
  };

  authCheck = () => {
    return new Promise((resolve, reject) => {
      firebaseAuthCheck()
        .then(({ uid, displayName, email }) => {
          const userData = { uid, displayName, email };
          resolve(userData);
        })
        .catch(() => reject());
    });
  };

  login = (email, password) => {
    return new Promise((resolve, reject) => {
      firebaseLogin(email, password)
        .then(({ uid, displayName, email }) => {
          const userData = { uid, displayName, email };
          resolve(userData);
        })
        .catch((error) => {
          console.log("Auth facade error", error);
          if (error === "auth/invalid-email") {
            reject(AuthError.invalidEmail());
          } else if (error === "auth/user-not-found") {
            reject(AuthError.userNotFound());
          } else if (error === "auth/wrong-password")
            reject(AuthError.wrongPassword());
          else {
            reject(AuthError.genericError());
          }
        });
    });
  };

  logout = () => {
    return firebaseSignout();
  };
}

export default AuthFacade;
