import {
  firebaseListPatients,
  firebaseListVisits,
  firebaseListImages,
  firebaseGenerateDownloadURL,
  firebaseGetName,
  firebaseGetGeneralUserAnaltycs,
  firebaseGetVisitDataPoint,
  firebaseGetVisitDataPoints,
  firebaseGetWoundDataPoint,
  firebaseGetWoundMetrics,
  firebaseGetImagePath,
  firebaseGetAllWoundMetrics,
  firebaseGetImagePathFromProcessed,
} from "services/firebase/firebaseStorage";

class DataStore {
  constructor() {}

  listPatients = async () => {
    return await firebaseListPatients();
  };

  listVisits = async (name) => {
    const visits = await firebaseListVisits(name);
    const res = {};
    visits.prefixes.forEach((x) => {
      res[x.name] = x.fullPath;
    });
    return { name, res };
  };

  getUserName = async () => {
    const name = await firebaseGetName();
    return name;
  };
  firebaseGetGeneralUserAnaltycs;

  getGeneralUserAnaltycs = async () => {
    const gua = await firebaseGetGeneralUserAnaltycs();
    return gua;
  };
  getVisitDataPoint = async (PID, VID) => {
    const data = await firebaseGetVisitDataPoint(PID, VID);
    return data;
  };
  fetchImageURL = async (path) => {
    const url = await firebaseGenerateDownloadURL(path);
    return { path, url };
  };

  fetchImagePathFromProcessed = async (PID, VID, searchString, dateString) => {
    const path = await firebaseGetImagePathFromProcessed(PID, VID, searchString, dateString);
    console.log(path, "from processed")
    return path;
  };

  fetchImagePath = async (PID, VID, searchString, dateString) => {
    const path = await firebaseGetImagePath(PID, VID, searchString, dateString);
    return path;
  };

  fetchImages = async (path) => {
    const images = await firebaseListImages(path);
    const imageFilters = {
      "365_NOFILTER": false,
      "395_NOFILTER": false,
      "415_NOFILTER": false,
      NOLED_NOFILTER: false,
      WHITE_NOFILTER: false,
      "365_415": false,
      "395_415": false,
      "415_415": false,
      "365_470": false,
      "395_470": false,
      "415_470": false,
      "365_525": false,
      "395_525": false,
      "415_525": false,
      "365_630": false,
      "395_630": false,
      "415_630": false,
    };
    Object.keys(images).forEach((imageName) => {
      Object.keys(imageFilters).forEach((filter) => {
        if (imageName.includes(filter) && imageFilters[filter] === false) {
          imageFilters[filter] = images[imageName];
        }
      });
    });
    return { path, imageFilters };
  };

  fetchVisitDataPoints = async () => {
    const data = await firebaseGetVisitDataPoints();
    return data;
  };

  fetchWoundDataPoint = async (PID) => {
    const data = await firebaseGetWoundDataPoint(PID);
    return data;
  };

  fetchWoundMetrics = async (PID) => {
    const data = await firebaseGetWoundMetrics(PID);
    return data;
  };
  getAllWoundMetrics = async () => {
    const data = await firebaseGetAllWoundMetrics()
    return data
  }
}

export default DataStore;
