import React from "react";
import { Button } from "@mui/material";
import JSZip from "jszip";
import styled from "styled-components";
import ImageComponent from "../WoundDash/ImageComponent";

import {saveAs} from "file-saver"
import { Download, DownloadDone } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";

const ImageDashView = (props) => {

  const [downloadState, setDownloadState] = useState(0) // 0 - not downloaded 1 - downloading 2 - downloaded

  useEffect(()=>{
    setDownloadState(0)
  },[])

  const getBase64Image = (img)=>{
    let canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    let dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  const generateVisitZip = () =>{
    setDownloadState(1)
    let zip = new JSZip(), count = 0, zipName = `${props.PID}_${props.VID}.zip`
    let links = [props.wlURL, props.fwlURL, props.gcURL, props.wsURL]
    links.forEach(async (link, x)=>{
      let fileName = link.split("%2Fimg%2F")[1].split("?")[0]
      console.log(fileName + " "+x)
      // let blob = await fetch(link).then(r => r.blob());
      let image = new Image()
      image.src = link
      image.crossOrigin = "Anonymous"
      zip.file(fileName, getBase64Image(image), {base64: true})

      if(x === links.length-1){
      let content = await zip.generateAsync({type: "blob"})
      
      saveAs(content, zipName)
    }
      setDownloadState(2)
    })
     
  }

  return (
    <Container>
      <Content>
        {props.woundMetrics && <MetaDiv>
          <Table>
            <TableRow>
              <span>Gram type</span>
              <span>Wound type</span>
              <span>Wound length</span>
              <span>Wound width</span>
              <span>Wound location</span>
            </TableRow>
            <TableRow>
              <span>{props.woundMetrics[props.VID] && (props.woundMetrics[props.VID].gramType === "" ? "NG" : props.woundMetrics[props.VID].gramType)}</span>
              <span>{props.woundDataPoint && props.woundDataPoint.woundType}</span>
              <span>{props.woundMetrics[props.VID] && props.woundMetrics[props.VID].woundLength} cm</span>
              <span>{props.woundMetrics[props.VID] && props.woundMetrics[props.VID].woundWidth} cm</span>
              <span>{props.woundDataPoint && props.woundDataPoint.woundLocation}</span>
            </TableRow>
           { props.woundMetrics[props.VID] && <span id="area-tag">
              Wound area:{" "}
              {(
                props.woundMetrics[props.VID].woundLength *
                props.woundMetrics[props.VID].woundWidth
              ).toFixed(2)}{" "}
              cm²
            </span>}
          </Table>
        </MetaDiv>}
        <div className="d-flex" style={{
          width: "100%"
        }}>
          <Button
          startIcon={ downloadState === 0 ? <Download/> : downloadState === 2 ? <DownloadDone /> : ""}
          onClick={generateVisitZip}
          sx={{
            marginLeft: "auto",
            marginRight: "10px"
          }}
        >
         { downloadState === 0 ? "Download All Images" : downloadState === 2 ? "Downloaded All images" : <> <i id="laoder" className="fas fa-spinner fa-spin"></i> <span>DownLoading...</span></>} 
        </Button>
        </div>
        
        <ImageDiv>
          <ImageComponent
            id={props.wlURL}
            url={props.wlURL}
            name={"White light"}
          />

          <ImageComponent
            id={props.wsURL}
            url={props.wsURL}
            name={"Wound area"}
          />
          <ImageComponent
            id={props.fwlURL}
            url={props.fwlURL}
            name={"Multi spectral image"}
          />
          <ImageComponent
            id={props.gcURL}
            url={props.gcURL}
            name={"Analyzed image"}
          />
        </ImageDiv>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  /* padding: 24px; */
`;
const Content = styled.div``;
const MetaDiv = styled.div`
  /* display: flex; */
  padding: 24px 36px;
`;
const ImageDiv = styled.div`
  display: flex;
  /* flex-direction: row; */
  width: 100%;
  justify-content: space-around;
  margin-top: 24px;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  #area-tag {
    /* margin-left: 50%; */
    align-self: center;
    font-weight: 900;
  }
`;
const TableRow = styled.div`
  /* background-color: aqua; */
  padding: 9px 18px;
  margin-bottom: 6px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  span {
    :nth-child(2) {
      /* margin-left: 100px; */
    }
  }
  &:nth-child(1) {
    background: #2a2a2a;
    color: #fff;
  }
  &:nth-child(2) {
    background: #e0e0e0;
  }
  /* &:nth-of-type(odd) {
    background: #e0e0e0;
  } */
`;

export default ImageDashView;
