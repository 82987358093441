import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
} from "firebase/auth";

export const firebaseSetPersistence = async (state) => {
  const auth = getAuth();
  if (state) {
    await setPersistence(auth, browserLocalPersistence);
    console.log("inMemSet");
  } else {
    await setPersistence(auth, browserSessionPersistence);
    console.log("bSesSet");
  }
};

export const firebaseLogin = (email, password) => {
  console.log(email, password);
  const auth = getAuth();
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredentials) => {
        console.log("Firebase service", userCredentials.user);
        resolve(userCredentials.user);
      })
      .catch((error) => {
        console.log("Firebase service error", error.code);
        reject(error.code);
      });
  });
};

export const firebaseAuthCheck = () => {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) resolve(user);
    else reject();
  });
};

export const firebaseSignout = () => {
  const auth = getAuth();
  return signOut(auth);
};
