import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserName, selectDataState } from "redux/dataSlice";
import MacroMetricsCarousel from "./components/MacroMetricsCarousel";
import MissingDataAlert from "./components/MissingDataAlert";
import Remainders from "./components/reminders";

const MainDashView = (props) => {

  const dispatch = useDispatch();
  const { userName } = useSelector(selectDataState);
  useEffect(()=>{
    dispatch(getUserName())
  },[])

  const [reminderOpen, setRemainderOpen] = useState(false)

  const handleReminderOpen = () =>{
    setRemainderOpen(!reminderOpen)
  }

  const [todayReminderCount, setTodayReminderCount] = useState(0)

  return (
    <div className="tab-contents" style={{paddingBottom:"100px"}}>
      <MacroMetricsCarousel
        userName = {userName}
        data={props.data}
        woundMetrics={props.woundMetrics}
        handleRemainderOpen = {handleReminderOpen}
        isReminderOpen = {reminderOpen}
        todayReminderCount = {todayReminderCount}
      />
      {/* <MissingDataAlert/> */}
      <Remainders isReminderOpen={reminderOpen} setTodayReminderCount={setTodayReminderCount} handleReminderOpen = {handleReminderOpen} />
    </div>
  );
};

export default MainDashView;
