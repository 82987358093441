import React from "react";
import SidebarView from "./SidebarView";
import { selectDataState, getUserName } from "redux/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import { getDatabase, onValue, ref as rtdbRef } from "firebase/database";
import { useState } from "react";
import {
  selectMainDashState,
  getVisitDataPoints,
  onDataChange,
  getAllWoundMetrics,
} from "redux/mainDashSlice";
import { populateDash } from "redux/dashboardSlice";
import { logout } from "redux/authSlice";
import { selectDashboardState, changeCurrentTab } from "redux/dashboardSlice";
import { useEffect } from "react";
import { firebaseGetAllWoundDataPoint, getOrInitApp, getUID } from "services/firebase/firebaseStorage";

const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

const onVisitDataPoints = () => {};

export const SidebarContainer = () => {
  const dispatch = useDispatch();
  const { userName } = useSelector(selectDataState);
  const { mainDashData, woundMetrics } = useSelector(selectMainDashState);
  const { currentTab } = useSelector(selectDashboardState);
  const [selectedPID, setSelectedPID] = useState("");
  const [allWoundDataPoints, setAllWoundDataPoints] = useState();
  console.log("selectedpid", selectedPID);

  console.log(mainDashData)

  const dataChange = (data) => {
    console.log("chg", data);
    dispatch(onDataChange(data));
  };
  useEffect(() => {
    if (!userName) {
      dispatch(getUserName());
    }
    if (!mainDashData.value) {
      // dispatch(getVisitDataPoints());
      // const db = getDatabase(getOrInitApp());
      // const UID = getUID();
      // onValue(rtdbRef(db, `${UID}/newMetrics/${UID}/woundDataPoints/VisitDataPoints`), (snapshot) => {
        // const val = snapshot.val();
        dispatch(getAllWoundMetrics());
        dispatch(getVisitDataPoints());
  
      // });
    }
    if (!woundMetrics) {
      dispatch(getAllWoundMetrics());
    }
    dispatch(getUserName());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(!mainDashData.value){ 
      console.log("mainDashData.value is null")
    }else{
      console.log(mainDashData)
      firebaseGetAllWoundDataPoint().then((WoundDataPoints)=>{
        console.log(WoundDataPoints)
        let tempDataPoints = {}
        Object.keys(WoundDataPoints).forEach(point=>{
          console.log(point, Object.keys(mainDashData.value).includes(point))
          if(point === "WoundMetrics" || point === "VisitDataPoints"){
            
          }else if(Object.keys(mainDashData.value).includes(point)){
            console.log(point)
            tempDataPoints[point] = !WoundDataPoints[point].name ? point : WoundDataPoints[point].name //`${WoundDataPoints[point].name}_${mainDashData.value[point][1].date}`
          }
        })
        setAllWoundDataPoints(tempDataPoints)
        console.log(tempDataPoints)
      })
    }
  }, [mainDashData.value])

  useEffect(() => {
    if (selectedPID && selectedPID !== "") {
      const data = insert(
        Object.keys(mainDashData.value[selectedPID]).map(
          (e) => `${selectedPID}/${e}`
        ),
        0,
        selectedPID + "/" + 0
      );
      console.log("populating", selectedPID, data);
      dispatch(populateDash({ PID: selectedPID, data }));
    }
  }, [mainDashData.value]);

  const logoutEvent = () => {
    dispatch(logout());
  };
  const onHomeClick = () => {
    setSelectedPID("");
    dispatch(changeCurrentTab(-2));
  };


  const onClickHandler = (PID) => {
    const data = insert(
      Object.keys(mainDashData.value[PID]).map((e) => `${PID}/${e}`),
      0,
      PID + "/" + 0
    );
    // data.splice(0, 0, 0);
    setSelectedPID(PID);
    console.log("populatingDash", data);
    dispatch(populateDash({ PID, data }));
  };

  // console.log(woundMetrics)
  // console.log(mainDashData.value)
  console.log(allWoundDataPoints)
  return (
    <SidebarView
      userName={userName}
      onClickHandler={onClickHandler}
      currentTab={currentTab}
      onLogout={logoutEvent}
      onHomeClick={onHomeClick}
      // patientList={mainDashData.value ? Object.keys(mainDashData.value) : []}
      // patientList = {getUID()=== "Cnt0GwQcBOOBEBTg5co5afeb2PC3" ? allWoundDataPoints ?allWoundDataPoints: [] : mainDashData.value ? Object.keys(mainDashData.value) : []}
      patientList = {allWoundDataPoints ?allWoundDataPoints: []}
    />
  );
};
