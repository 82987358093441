import React from "react";
import { ROUTES } from "navigation/contants";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router";
import {
  initApp,
  selectAuthState,
  login,
  setPersistence,
} from "redux/authSlice";

import LoginView from "./LoginView";

export const LoginContainer = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const { user, isFetching, authError } = useSelector(selectAuthState);

  useEffect(() => {
    dispatch(initApp());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (authError) {
      authError.match({
        invalidEmail: () => {
          alert("Invalid credentials");
        },
        userNotFound: () => {
          alert("Invalid credentials");
        },
        wrongPassword: () => {
          alert("Invalid credentials");
        },
        genericError: () => {
          alert("Invalid credentials");
        },
      });
    }
  }, [authError]);

  const loginEvent = () => {
    dispatch(login({ email, password }));
  };

  const setPersistenceEvent = (state) => {
    dispatch(setPersistence({ state }));
  };

  return user ? (
    <Redirect to={ROUTES.ROOT} />
  ) : (
    <LoginView
      setEmail={setEmail}
      setPassword={setPassword}
      onLogin={loginEvent}
      onSetPersistence={setPersistenceEvent}
      isFetching={isFetching}
    />
  );
};
