import { initializeApp, getApps } from "firebase/app";

import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";

export const firebaseInit = async () => {
  console.log("Firebase initiated");

  if (getApps().length === 0) {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DATABASE_URL,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID
    };
    initializeApp(firebaseConfig);
    const auth = getAuth();
    await setPersistence(auth, browserSessionPersistence);
  }
};
