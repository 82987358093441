import { initializeApp, getApps, getApp } from "firebase/app";
import { getDatabase, onValue, ref as rtdbRef, update } from "firebase/database";
import {
  getStorage,
  ref,
  list,
  listAll,
  getDownloadURL,
} from "firebase/storage";
import { getAuth } from "firebase/auth";

export const getOrInitApp = () => {
  const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
  };
  return getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
};

export const getUID = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  return user.uid;
};

export const firebaseGetName = async () => {
  const db = getDatabase(getOrInitApp());
  const UID = getUID();
  return new Promise((resolve, reject) => {
    onValue(
      rtdbRef(db, `${UID}/dynamicUserConfiguration/userName`),
      (snapshot) => {
        const username = snapshot.val();
        onValue(
          rtdbRef(db, `${UID}`),
          (snapshot) => {
            console.log(snapshot.val()) 
          }
        );
        resolve(username);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

export const firebaseGetUserConfig = async () => {
  const db = getDatabase(getOrInitApp());
  const UID = getUID();
  return new Promise((resolve, reject) => {
    try{
      onValue(
      rtdbRef(db, `${UID}/dynamicUserConfiguration`),
      (snapshot) => {
        const userConfig = snapshot.val();
        resolve(userConfig);
      },
      {
        onlyOnce: true,
      }
    );
    }catch(e){
      reject(e)
    }
    
  });
};

export const firebaseGetPrintParameters = async () => {
  const db = getDatabase(getOrInitApp());
  const UID = getUID();
  return new Promise((resolve, reject) => {
    try{
      onValue(
      rtdbRef(db, `${UID}/printParameters`),
      (snapshot) => {
        const userConfig = snapshot.val();
        resolve(userConfig);
      },
      {
        onlyOnce: true,
      }
    );
    }catch(e){
      reject(e)
    }
    
  });
};



export const firebaseGetGeneralUserAnaltycs = async () => {
  const db = getDatabase(getOrInitApp());
  const UID = getUID();
  return new Promise((resolve, reject) => {
    onValue(
      rtdbRef(db, `${UID}/generalUserAnalytics`),
      (snapshot) => {
        console.log(snapshot);
        const gua = snapshot.val();
        resolve(gua);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

export const firebaseGetWoundDataPoint = async (PID) => {
  const db = getDatabase(getOrInitApp());
  const UID = getUID();
  return new Promise((resolve, reject) => {
    console.log(PID);
    onValue(
      rtdbRef(db, `${UID}/newMetrics/${UID}/WoundDataPoints/${PID}`),
      (snapshot) => {
        console.log(snapshot.val());
        const val = snapshot.val();
        resolve(val);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

export const firebaseGetAllWoundDataPoint = async () => {
  const db = getDatabase(getOrInitApp());
  const UID = getUID();
  return new Promise((resolve, reject) => {
    
    onValue(
      rtdbRef(db, `${UID}/newMetrics/${UID}/WoundDataPoints`),
      (snapshot) => {
        console.log(snapshot.val());
        const val = snapshot.val();
        resolve(val);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

export const firebaseGetWoundMetrics = async (PID) => {
  const db = getDatabase(getOrInitApp());
  const UID = getUID();
  return new Promise((resolve, reject) => {
    console.log(PID);
    onValue(
      rtdbRef(db, `${UID}/newMetrics/${UID}/WoundDataPoints/WoundMetrics/${PID}`),
      (snapshot) => {
        console.log(snapshot.val());
        const val = snapshot.val();
        resolve(val);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

export const firebaseGetVisitData = async (PID, VID) => {
  const db = getDatabase(getOrInitApp());
  const UID = getUID();
  console.log(`${UID}/newMetrics/${UID}/WoundDataPoints/VisitDataPoints/${UID}/${PID}/${VID}`);
  return new Promise((resolve, reject) => {
    onValue(
      rtdbRef(db, `${UID}/newMetrics/${UID}/WoundDataPoints/VisitDataPoints/${UID}/${PID}/${VID}`),
      (snapshot) => {
        console.log(snapshot);
        const val = snapshot.val();
        resolve(val);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

export const firebaseGetVisitDataPoint = async (PID, VID) => {
  const db = getDatabase(getOrInitApp());
  const UID = getUID();
  console.log(`newMetrics/VisitDataPoints/${UID}/${PID}/${VID}`);
  return new Promise((resolve, reject) => {
    onValue(
      rtdbRef(db, `${UID}/newMetrics/${UID}/WoundDataPoints/VisitDataPoints/${PID}/${VID}`),
      (snapshot) => {
        console.log(snapshot);
        const val = snapshot.val();
        resolve(val);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

export const firebaseGetAllWoundMetrics = async () => {
  const db = getDatabase(getOrInitApp());
  const UID = getUID();
  return new Promise((resolve, reject) => {
    onValue(
      rtdbRef(db, `${UID}/newMetrics/${UID}/WoundDataPoints/WoundMetrics/`),
      (snapshot) => {
        const val = snapshot.val();
        resolve(val);
      },
      {
        onlyOnce: true,
      }
    );
  });
};



export const firebaseGetVisitDataPoints = async () => {
  const db = getDatabase(getOrInitApp());
  const UID = getUID();
  return new Promise((resolve, reject) => {
    onValue(
      rtdbRef(db, `${UID}/newMetrics/${UID}/WoundDataPoints/VisitDataPoints`),
      (snapshot) => {
        console.log(snapshot.val());
        const val = snapshot.val();
        resolve(val);
      },
      {
        onlyOnce: true,
      }
    );
    // firebaseOnVisitDataPoints(console.log);
  });
};

export const firebaseOnVisitDataPoints = (onChange) => {
  const db = getDatabase(getOrInitApp());
  const UID = getUID();
  console.log("ListnerSet");

  onValue(rtdbRef(db, `${UID}/newMetrics/${UID}/WoundDataPoints/VisitDataPoints`), (snapshot) => {
    const val = snapshot.val();
  });
};

export const firebaseListPatients = async () => {
  const UID = getUID();
  const storage = getStorage(getOrInitApp());
  const patientsRef = ref(storage, `users/${UID}`);
  const firstPage = await listAll(patientsRef);
  const res = {};
  firstPage.prefixes.forEach((x) => {
    res[x.name] = null;
  });
  return res;
};



export const firebaseListVisits = async (name) => {
  const UID = getUID();
  const storage = getStorage(getOrInitApp());
  const visitsRef = ref(storage, `users/${UID}/${name}`);
  const visits = await listAll(visitsRef);
  return visits;
};

export const firebaseListImages = async (path) => {
  const storage = getStorage(getOrInitApp());
  const imagesRef = ref(storage, path + "/img");
  const images = await listAll(imagesRef);
  const res = {};
  images.items.forEach((image) => {
    if (image.name.includes(".jpg")) {
      res[image.name] = image.fullPath;
    }
  });
  return res;
};

export const firebaseGetPatientListFromStorage = async () =>{
  const storage = getStorage(getOrInitApp());
  const UID = getUID();

  const patientsRef = ref(storage, `users/${UID}`)
  const patientList = await listAll(patientsRef);

  return patientList

}

export const firebaseGetVisitListFromStorage = async (PID) =>{
  const storage = getStorage(getOrInitApp());
  const UID = getUID();

  const listRef = ref(storage, `users/${UID}/${PID}`)
  const list = await listAll(listRef);

  return list
}

export const firebaseGetImagePathFromProcessed = async (PID, VID, searchString, dateString) => {
  const storage = getStorage(getOrInitApp());
  const UID = getUID();
  // const UID = "aRmbxGtX4Tejz7k6GIIs90ZLewP2";
  // PID = "ADDEMO4_4";
  // // VID = "1";
  // if (VID > 2) {
  //   VID = "1";
  // }
  const imagesRef = ref(storage,  `users/${UID}/${PID}/${VID}/img/Processed`)

  const images = await listAll(imagesRef);
    
    console.log(images, imagesRef )
    let res = null;
    images.items.forEach((image) => {
      if(dateString){
        if (image.name.includes(searchString) && !image.name.includes("HI_") && image.name.includes(dateString)) {
          res = image.fullPath;
        }
      }else if (image.name.includes(searchString) && !image.name.includes("HI_")) {
        res = image.fullPath;
      }
    });
  return res
};



export const firebaseGetImagePath = async (PID, VID, searchString, dateString) => {
  const storage = getStorage(getOrInitApp());
  const UID = getUID();
  // const UID = "aRmbxGtX4Tejz7k6GIIs90ZLewP2";
  // PID = "ADDEMO4_4";
  // // VID = "1";
  // if (VID > 2) {
  //   VID = "1";
  // }
  const imagesRef = ref(storage, `users/${UID}/${PID}/${VID}/img/`);
  const images = await listAll(imagesRef);
    
    console.log(images)
    let res = null;
    images.items.forEach((image) => {
      if (dateString) {
        console.log(dateString)
        if (image.name.includes(dateString) && image.name.includes(searchString)){
          res = image.fullPath
          console.log(res, "withDateString")
        }
      }else if (image.name.includes(searchString)) {
        res = image.fullPath;
      }
    });
  return res
};

export const firebaseGenerateDownloadURL = async (path) => {
  const storage = getStorage(getOrInitApp());
  const imageRef = ref(storage, path);
  console.log(path);
  return await getDownloadURL(imageRef);
};


export const firebaseGetDeviceDetail = async () =>{
  const db = getDatabase(getOrInitApp());
  const UID = getUID();
  return new Promise((resolve, reject) => {
    try{onValue(
      rtdbRef(db, `${UID}/aboutDevice`),
      (snapshot) => {
        const val = snapshot.val();
        // onValue(rtdbRef(db, `${UID}/dynamicUserConfiguration`), userInfo=>{
        //   resolve({...userInfo.val(), ...val});
        // })   
        resolve(val)     
      },
      {
        onlyOnce: true,
      }
    )}
    catch(e){
      reject(e)
    }
  });
}

export const updateUserData = (data) =>{
  const db = getDatabase(getOrInitApp());
  const UID = getUID();
  console.log(data)
  return new Promise((resolve, reject)=>{
    update(rtdbRef(db, `${UID}/dynamicUserConfiguration`), data).then(response=>{
      resolve(response)
    }).catch(err=>{
      console.error(err);
      reject(err)
    })
  })

}

export const firebaseGetHospitalLogo = ()=>{
  const db = getDatabase(getOrInitApp());
  const UID = getUID();

  return new Promise((resolve, reject)=>{
    try{onValue(
      rtdbRef(db, `${UID}/dynamicUserConfiguration`),
      (snapshot) => {
        const val = snapshot.val();
        resolve(val)     
      },
      {
        onlyOnce: true,
      }
    )}
    catch(e){
      reject(e)
    }
  })
  
}

const uploadHospitalLogo = (img) =>{

}

export const firebaseGetMissingData = () =>{
  const db = getDatabase(getOrInitApp());
  const UID = getUID();
  return new Promise((resolve, reject)=>{
    try{onValue(
      rtdbRef(db, `${UID}/missings`),
      (snapshot) => {
        const val = snapshot.val();
        resolve(val)     
      },
      {
        onlyOnce: true,
      }
    )}
    catch(e){
      reject(e)
    }
  })
}

const getTodayReminderKey = () => {
  let currentDate = new Date();
  let day = currentDate.getDate();
  let month = currentDate.getMonth() + 1;
  let year = currentDate.getFullYear();

  // Add a leading zero to the day and month if they're less than 10
  day = day < 10 ? '0' + day : day;
  month = month < 10 ? '0' + month : month;

  let dateFormatted = day.toString() + month.toString() + year.toString();
  return `date_${dateFormatted}`

}

export const firebaseGetReminderList = () =>{
  const db = getDatabase(getOrInitApp());
  const UID = getUID();
  return new Promise((resolve, reject)=>{
    try{onValue(
      rtdbRef(db, `${UID}/remainders/${getTodayReminderKey()}`),
      (snapshot) => {
        const val = snapshot.val();
        resolve(val)     
      },
      {
        onlyOnce: true,
      }
    )}
    catch(e){
      reject(e)
    }
  })
}