import React from "react";/* eslint-disable jsx-a11y/anchor-is-valid */
import ImageDashboard from "components/ImageDashboard";
import MainDash from "components/MainDash";
import WoundDash from "components/WoundDash";
import VisitDash from "components/VisitDash";
import Frame from "./frame";
import VisitBar from "./visitBar";
import { useEffect, useRef } from "react";
import { useState } from "react";

// import Wound

const IFrameView = (props) => {
  const [visit, setVisit] = useState(props.currentTab)
  const navTab = useRef()
  const scroll = (scrollOffset) => {
    navTab.current.scrollLeft += scrollOffset;
  };
  console.log(props.currentTab)
  
  const handlevisit = (e)=>{
    e.preventDefault();
    if(!!e.target.value && e.target.value > 0 && e.target.value <= props.max){
        setVisit(e.target.value)
        handleTab(e.target.value)
    }else if(!!e.target.value && e.target.value > props.max){
        setVisit(props.max)
        handleTab(props.max)
    }else{
        setVisit(0)
        handleTab(0)
    }
}
  useEffect(()=>{
    if(!!navTab && !!navTab.current && props.currentTab >= 6 && props.currentTab < 20 ){
      scroll(props.currentTab*20)
    }

    if(!!navTab && !!navTab.current && props.currentTab >= 21 ){
      scroll(props.currentTab*65)
    }
  }, [props.currentTab])

  const handleTab = (value) =>{
    props.onTabClick(props.tabs[value].split("/").at(1))
  }
  return (
    <div
      className="content-wrapper iframe-mode"
      data-loading-screen={750}
      style={{
        height: "98vh",
        padding: "0 2% 2% 4%",
      }}
    >
      {props.tabs.length > 0 && (
        <div className="nav navbar navbar-expand navbar-white navbar-light border-bottom p-0 sticky-top" style={{backgroundColor:"#f4f6f9"}}>
          <a className="nav-link bg-light" data-widget="pushmenu" role="button"  style={{backgroundColor:"#f4f6f9"}}>
            <i className="fas fa-bars" />
          </a>

          {/* <a
            className="nav-link bg-light"
            role="button"
            onClick={(e) => {
              e.preventDefault();
              props.onMoveTab(-1);
            }}
          >
            <i className="fas fa-angle-double-left" />
          </a> */}

                    <a
                    className={`nav-link ${
                      props.currentTab===0 && "active"
                    }`}
                    role="button"
                    onClick={(e) => {
                      e.preventDefault();
                      props.onTabClick(0);
                    }}
                  >
                    Overview
                  </a>
                  
                  <VisitBar visit = {visit} handlevisit = {handlevisit} currentTab = {props.currentTab} handleTab = {handleTab} max = {props.tabs.length-1} />

          <ul className="navbar-nav " ref={navTab} role="tablist" style={{
            overflowY : "auto",
            margin:"auto 20px"
          }}>
            {props.tabs.map((e, index) => {
              console.log(props.tabs, e);
              return (
                  e.split("/").at(1) !== "0"
                      && !!e && <li
                  id = {index}
                  className={`nav-item ${
                    index === props.tabs.indexOf(`${props.tabs[0].split("/")[0]}/${props.currentTab}`) && "active"
                  }`}
                  role="presentation"
                  key={index}
                  style={{
                    margin:"auto 8px"
                  }}
                >
                  <a
                    className={`nav-link ${
                      index === props.tabs.indexOf(`${props.tabs[0].split("/")[0]}/${props.currentTab}`) && "active"
                    }`}
                    role="button"
                    onClick={(a) => {
                      a.preventDefault();
                      props.onTabClick(e.split("/").at(1));
                    }}
                  >
                  { "Visit " + e.split("/").at(1)}
                  </a>
                </li> 
              );
            })}
          </ul>

          <a
            className="nav-link bg-light"
            role="button"
            onClick={(e) => {
              e.preventDefault();
              props.onTabClick(props.tabs[props.tabs.length -1].split("/").at(1));
              console.log(navTab)
            }}
          >
            <i className="fas fa-angle-double-right" />
          </a>
        </div>
      )}
      {props.currentTab >= 0 && (
        <Frame currentTab = {props.tabs[props.tabs.indexOf(`${props.tabs[0].split("/")[0]}/${props.currentTab}`)]} />
      )}
      {props.currentTab === -1 && (
        <div className="tab-content">
          <div className="tab-empty" style={{ height: 500 }}>
            <h2 className="display-4" style={{ fontSize: "16px" }}>
              No tab selected
            </h2>
          </div>
        </div>
      )}
      {props.currentTab === -2 && <MainDash />}
    </div>
  );
};
export default IFrameView;
