import { SettingsOutlined } from '@mui/icons-material'
import { Dialog, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles';
import Loader from 'components/loader/loader'
import React from 'react'
import { useState } from 'react'
import SettingsContainer from './settingsContainer'

function ProfileSetting({userInfo}) {
    const [isOpen, setIsOpen] = useState(false)
    const handleClose = () =>{
        setIsOpen(!isOpen)
    }

    const CustomDialog = styled(Dialog)(({ theme }) => ({
        '.MuiPaper-root':{
            borderRadius:"25px"
        }
      }));

  return (
    <>
    <IconButton
        onClick={handleClose}
    >
        <SettingsOutlined  />
    </IconButton>
    <CustomDialog
        open={isOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
    >
        <h2 style={{padding:"1% 1%"}}> <SettingsOutlined/> Profile Settings</h2>
       
        <SettingsContainer userInfo = {userInfo} />
    </CustomDialog>
    </>
  )
}

export default ProfileSetting