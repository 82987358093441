import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import AuthFacade from "services/facade/auth";

const initialState = {
  user: null,
  isFetching: true,
  isSuccess: false,
  authError: null,
};

const mAuth = new AuthFacade();

export const checkAuthStatus = createAsyncThunk("auth/re-auth-session", () => {
  return new Promise((resolve, reject) => {
    mAuth
      .authCheck()
      .then((user) => {
        resolve(user);
      })
      .catch(() => reject());
  });
});

export const initApp = createAsyncThunk("auth/init", (params, thunkAPI) => {
  return new Promise((resolve, reject) => {
    mAuth.init().then(() => {
      resolve();

      thunkAPI.dispatch(checkAuthStatus());
    });
  });
});

export const login = createAsyncThunk(
  "auth/login",
  ({ email, password }, thunkAPI) => {
    return new Promise((resolve, reject) => {
      mAuth
        .login(email, password)
        .then((user) => {
          resolve(user);
        })
        .catch((authError) => {
          console.log(authError);
          reject(thunkAPI.rejectWithValue(authError));
        });
    });
  }
);

export const logout = createAsyncThunk("auth/logout", () => {
  mAuth.logout();
});

export const setPersistence = createAsyncThunk(
  "auth/logout",
  ({ state }, thunkAPI) => {
    mAuth.setPersistence(state);
  }
);

export const authSlice = createSlice({
  name: "authState",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.authError = null;
    },
  },
  extraReducers: {
    [checkAuthStatus.pending]: (state) => {
      state.isFetching = true;
    },
    [checkAuthStatus.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.isFetching = false;
      state.isSuccess = false;
      state.authError = null;
    },
    [checkAuthStatus.rejected]: (state) => {
      state.isFetching = false;
    },
    [login.pending]: (state) => {
      state.user = null;
      state.isFetching = true;
      state.isSuccess = false;
      state.authError = null;
    },
    [login.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.isFetching = false;
      state.isSuccess = false;
      state.authError = null;
    },
    [login.rejected]: (state, { payload }) => {
      state.user = null;
      state.isFetching = false;
      state.isSuccess = false;
      state.authError = payload;
    },
    [logout.fulfilled]: (state) => {
      state.user = null;
    },
  },
});

export const selectAuthState = (state) => state.authState;

export const { clearState } = authSlice.actions;

export default authSlice.reducer;
