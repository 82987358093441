import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DataStore from "services/facade/dataStore";

const initialState = {
  data: {},
  imageURLData: {},
  isFetching: false,
  isInnerFetching: false,
  isError: false,
};

const mDataStore = new DataStore();

export const fetchImages = createAsyncThunk(
  "data/fetch-images",
  (path, thunkAPI) => {
    return new Promise((resolve, reject) => {
      mDataStore.fetchImages(path).then((data) => {
        resolve(data);
      });
    });
  }
);

export const fetchImageURL = createAsyncThunk(
  "data/fetch-image-url",
  (path, thunkAPI) => {
    return new Promise((resolve, reject) => {
      mDataStore.fetchImageURL(path).then((data) => {
        resolve(data);
      });
    });
  }
);

export const imageSlice = createSlice({
  name: "imageState",
  initialState,
  reducers: {
    pushNewImage: (state, payload) => {
      console.log(payload);
    },
  },
  extraReducers: {
    [fetchImages.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchImages.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.data[payload.path] = payload.imageFilters;
    },
    [fetchImages.rejected]: (state) => {
      state.isFetching = false;
    },
    [fetchImageURL.pending]: (state) => {
      // state.isInnerFetching = true;
    },
    [fetchImageURL.fulfilled]: (state, { payload }) => {
      // state.isInnerFetching = false;
      state.imageURLData[payload.path] = payload.url;
    },
    [fetchImageURL.rejected]: (state) => {
      // state.isInnerFetching = false;
    },
  },
});

export const selectImageState = (state) => state.imageState;

export const { pushNewImage } = imageSlice.actions;

export default imageSlice.reducer;
