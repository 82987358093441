import React from "react";
import VisitDashView from "./VisitDashView";
import ImageDashView from "./ImageDashView";
import Report from "./Report.jsx";
import { useDispatch, useSelector } from "react-redux";
import {
  selectVisitDashState,
  fetchVisitDataPoint,
  fetchImageAndURL,
} from "redux/visitDashSlice";
import { selectWoundDashState, fetchWoundMetrics } from "redux/woundDashSlice";
import { selectDashboardState } from "redux/dashboardSlice";
import { useEffect, useState, useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {  toJpeg } from 'html-to-image';
import { getAuth } from "firebase/auth";
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import { height } from "@mui/system";


export const VisitDashContainer = (props) => {
  const { PID, VID } = useSelector(selectDashboardState);
  const woundDashState = useSelector(selectWoundDashState);
  const { data } = useSelector(selectVisitDashState);
  const dispatch = useDispatch();
  const [showImageDash, setShowImageDash] = useState(false);
  const [tab, setTab] = useState(0);
  const [loading, setloading] = useState(false);

  console.log(getAuth())

  const report = useRef()

  console.log(PID, VID)

  const getDate = (string) =>{ //10062023160035
    console.log(string, string ? `${string.slice(4,8)}_${string.slice(2,4)}_${string.slice(0,2)}` : null)
    return string ? `_${string.slice(4,8)}_${string.slice(2,4)}_${string.slice(0,2)}_` : null
}

  useEffect(() => {
    if (!data[PID + "/" + VID] || !data[PID + "/" + VID].visitDataPoint) {
      dispatch(fetchVisitDataPoint({ PID, VID }));
    }
    if (!woundDashState.data[PID] || !woundDashState.data[PID].woundMetrics) {
      dispatch(fetchWoundMetrics({ PID }));
    }
    if(data[PID + "/" + VID] && (data[PID + "/" + VID].visitDataPoint)){
      dispatch(fetchImageAndURL({ PID, VID, searchStr: "WHITE_NOFILTER", dateStr: getDate(data[PID + "/" + VID].visitDataPoint.date) }));
      dispatch(fetchImageAndURL({ PID, VID, searchStr: "395_NOFILTER" , dateStr: getDate(data[PID + "/" + VID].visitDataPoint.date)}));
    
    if (!data[PID + "/" + VID] || !data[PID + "/" + VID]["generated_cropped"]) {
      dispatch(fetchImageAndURL({ PID, VID, searchStr: "generated_cropped" }));
    }
    if (!data[PID + "/" + VID] || !data[PID + "/" + VID]["woundsize"]) {
      dispatch(fetchImageAndURL({ PID, VID, searchStr: "woundsize" }));
    }
    }
    
  }, [PID, VID, data[PID+"/"+VID]]);

  console.log(data)

  const generate = () => {
    setloading(true);
    // console.log(document.getElementById("report-main"));
    html2canvas(document.querySelector("#report-main"), {
      allowTaint: true,
      useCORS: true,
      scale: 2
    })
      .then((canvas) => {
        // document.body.appendChild(canvas); // if you want see your screenshot in body.
        const imgData = canvas.toDataURL("image/png");
        console.log("printing...")
        const pdf = new jsPDF("p", "mm", "a4");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 0,  pdfWidth, pdfHeight);
        pdf.autoPrint();
        pdf.save(`${PID}_${VID}_REPORT.pdf`);
        setloading(false);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  return PID !== null && VID !== 0 && data[PID + "/" + VID] ? (
    <>
      <div
        style={{
          display: "flex",
          width: "120px",
          alignItems: "center",
          justifyContent: "center",
          margin: "24px 150px 0 auto",
          // marginLeft: "auto",
        }}
      >
        <button
          type="button"
          style={{
            margin: 0,
          }}
          className={
            // eslint-disable-next-line eqeqeq
            tab === 0
              ? "btn btn-block btn-primary"
              : "btn btn-block btn-default"
          }
          onClick={() => {
            // setShowImageDash(false);
            setTab(0);
          }}
        >
          Dashboard
        </button>
        
          <button
            type="button"
            className={
              // eslint-disable-next-line eqeqeq
              tab === 1
                ? "btn btn-block btn-primary"
                : "btn btn-block btn-default"
            }
            style={{
              margin: 0,
              whiteSpace: "nowrap",
              textAlign: "center",
              marginLeft: "6px",
              // wordWrap: "nowrap",
            }}
            onClick={() => {
              generate();
              setTab(1);
            }}
          >
            Report
          </button>
      

      {/* {!showImageDash ? (
        <VisitDashView
          PID={PID}
          VID={VID}
          visitDataPoint={data[PID + "/" + VID].visitDataPoint}
          woundMetrics={woundDashState.data[PID].woundMetrics}
          wlURL={data[PID + "/" + VID]["WHITE_NOFILTER"]}
        />
      ) : (
        <ImageDashView
          PID={PID}
          VID={VID}
          woundDataPoint={woundDashState.data[PID].woundDataPoint}
          woundMetrics={woundDashState.data[PID].woundMetrics}
          wlURL={data[PID + "/" + VID]["WHITE_NOFILTER"]}
          fwlURL={data[PID + "/" + VID]["395_NOFILTER"]}
          gcURL={data[PID + "/" + VID]["generated_cropped"]}
          wsURL={data[PID + "/" + VID]["woundsize"]}
        />
      )} */}
      {tab === 1 && (
        
          <button
            type="button"
            className={
              // eslint-disable-next-line eqeqeq
              tab === 1
                ? loading
                  ? "btn btn-block btn-secondary disabled"
                  : "btn btn-block btn-secondary "
                : "btn btn-block btn-default"
            }
            style={{
              margin: 0,
              whiteSpace: "nowrap",
              textAlign: "center",
              marginLeft: "6px",
              // wordWrap: "nowrap",
            }}
            onClick={() => {
              generate();
              // exportComponentAsJPEG(report)
            }}
          >
            {loading ? (
              <i id="laoder" className="fas fa-spinner fa-spin"></i>
            ) : (
              "Download"
            )}
          </button>
        
      )}
      </div>

      {tab === 0 ? (
        <div  style={{marginBottom:"100px", backgroundColor:"#f4f6f9"}}>
          <VisitDashView
            PID={PID}
            VID={VID}
            visitDataPoint={data[PID + "/" + VID].visitDataPoint}
            woundMetrics={woundDashState.data[PID].woundMetrics}
            wlURL={data[PID + "/" + VID]["WHITE_NOFILTER"]}
          />
           <ImageDashView
            PID={PID}
            VID={VID}
            woundDataPoint={woundDashState.data[PID].woundDataPoint}
            woundMetrics={woundDashState.data[PID].woundMetrics}
            wlURL={data[PID + "/" + VID]["WHITE_NOFILTER"]}
            fwlURL={data[PID + "/" + VID]["395_NOFILTER"]}
            gcURL={data[PID + "/" + VID]["generated_cropped"]}
            wsURL={data[PID + "/" + VID]["woundsize"]}
          />
        </div>
      ) :(
        <div
          
          style={{
            // width: "100%",

            boxSizing: "border-box",
            marginLeft: "50px",
            marginTop: "50px",
            marginBottom: "50px",
          }}
        >
          <div ref= {report}>
            <Report
              PID={PID}
              VID={VID}
              woundDataPoint={woundDashState.data[PID].woundDataPoint}
              woundMetrics={woundDashState.data[PID].woundMetrics}
              wlURL={data[PID + "/" + VID]["WHITE_NOFILTER"]}
              fwlURL={data[PID + "/" + VID]["395_NOFILTER"]}
              gcURL={data[PID + "/" + VID]["generated_cropped"]}
            />
          </div>
        </div>
      )}
    </>
  ) : (
    <p>No visit data</p>
  );
};


{/* <button
          type="button"
          className={
            // eslint-disable-next-line eqeqeq
            tab === 1
              ? "btn btn-block btn-primary"
              : "btn btn-block btn-default"
          }
          style={{
            margin: 0,
            marginLeft: "6px",
          }}
          onClick={() => {
            // setShowImageDash(true);
            setTab(1);
          }}
        >
          Images
        </button> */}