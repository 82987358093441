import React from "react";
import MainDashView from "./MainDashView";
import { getGeneralUserAnaltycs, selectDataState } from "redux/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectMainDashState, getVisitDataPoints } from "redux/mainDashSlice";
import { useEffect } from "react";
import { selectWoundDashState } from "redux/woundDashSlice";
import Loader from "components/loader/loader";
import MissingDataAlert from "./components/MissingDataAlert";
export const MainDashContainer = () => {
  const { generalUserAnalytics } = useSelector(selectDataState);
  const { processedDashData, woundMetrics, vData } = useSelector(selectMainDashState);
  const { imageWL, data } = useSelector(selectWoundDashState);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!generalUserAnalytics) {
      dispatch(getGeneralUserAnaltycs());
    }
  }, []);

  console.log("asdf", woundMetrics);
  console.log(processedDashData)
  console.log(vData)
  return Object.keys(processedDashData).length !== 0 && woundMetrics ? (
    <>
      <MainDashView data={processedDashData} woundMetrics={woundMetrics} />
 
    </>
  ) : (
    <Loader width={200} height={200} />
  );
};
