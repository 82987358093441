import React from "react";/* eslint-disable no-undef */
import IlluminateActivityGraph from "./IlluminateActivtyGraph";
import DatePickerComponent from "./DatePickerComponent";
import { useState, useEffect } from "react";
import { Notifications, NotificationsNoneOutlined } from "@mui/icons-material";
import { Badge, IconButton } from "@mui/material";
// import moment;
const MacroMetricsCarousel = (props) => {
  const [startDate, setStartDate] = useState(
    new Date(new Date(new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0))
  );
  // console.log(props)
  const [endDate, setEndDate] = useState(new Date());
  // eslint-disable-next-line no-undef
  const [aggregateData, setAggregateData] = useState({
    totalVisits: 0,
    newPatients: 0,
    recurringPatients: 0,
  });
  const [selectedData, setSelectedData] = useState({
    totalVisits: 0,
    newPatients: 0,
    recurringPatients: 0,
  });

  const [gramMetrics, setGramMetrics] = useState([0, 0, 0, 0])

  const [graphData, setGraphData] = useState({});
  useEffect(() => {
    const tmp = {
      totalVisits: 0,
      newPatients: 0,
      recurringPatients: 0,
    };
    const tmp2 = {
      totalVisits: 0,
      newPatients: 0,
      recurringPatients: 0,
    };

    let tmp3 = {};

    let vals = [0, 0, 0, 0];
    console.log("woundMetrics", props.woundMetrics);
    if (props.woundMetrics) {
      Object.keys(props.woundMetrics).forEach((patient) => {
        const vMetrics = props.woundMetrics[patient];
        console.log(vMetrics)
        Object.keys(vMetrics).forEach((e, index) => {
          if (vMetrics[e]["date"]) {
            if ((moment(vMetrics[e]["date"].slice(0, 8), "DDMMYYYY").toDate() >= startDate) && (moment(vMetrics[e]["date"].slice(0, 8), "DDMMYYYY").toDate() <= endDate)) {
              console.log("metricDate", vMetrics[e]["date"].slice(0, 8), " >> ", moment(vMetrics[e]["date"].slice(0, 8), "DDMMYYYY").toDate().toLocaleDateString());
              if (vMetrics[e]["gramType"] === "NG" || vMetrics[e]["gramType"] === "") {
                console.log("Adding NG", vMetrics[e]["gramType"], patient, index);
                vals[0] += 1;
              } else if (vMetrics[e]["gramType"] === "GN") {
                console.log("Adding GN", vMetrics[e]["gramType"], patient, index);
                vals[1] += 1;
              } else if (vMetrics[e]["gramType"] === "GP") {
                console.log("Adding GP", vMetrics[e]["gramType"], patient, index);
                vals[2] += 1;
              } else if (vMetrics[e]["gramType"] === "GN,GP" || vMetrics[e]["gramType"] === "GN&GP" || vMetrics[e]["gramType"] === "GNGP") {
                console.log("Adding GNGP", vMetrics[e]["gramType"], patient, index);
                vals[3] += 1;
              }
            }
          } else {
            if (vMetrics[e]["gramType"] === "NG" || vMetrics[e]["gramType"] === "") {
              console.log("Adding NG", vMetrics[e]["gramType"], patient, index);
              vals[0] += 1;
            } else if (vMetrics[e]["gramType"] === "GN") {
              console.log("Adding GN", vMetrics[e]["gramType"], patient, index);
              vals[1] += 1;
            } else if (vMetrics[e]["gramType"] === "GP") {
              console.log("Adding GP", vMetrics[e]["gramType"], patient, index);
              vals[2] += 1;
            } else if (vMetrics[e]["gramType"] === "GN,GP" || vMetrics[e]["gramType"] === "GN&GP" || vMetrics[e]["gramType"] === "GNGP") {
              console.log("Adding GNGP", vMetrics[e]["gramType"], patient, index);
              vals[3] += 1;
            }
          }

        });
      });
    }

    setGramMetrics(vals)

    Object.keys(props.data).forEach((date) => {
      // console.log( startDate.getTime(), date, new Date(moment(date, "DDMMYYYY").toDate().toLocaleDateString()).getTime(), new Date(moment(date, "DDMMYYYY").toDate().toLocaleDateString()) >= startDate );
      if (!date.includes("-")) {
        if (moment(date, "DDMMYYYY").toDate() < startDate) {
          tmp["totalVisits"] += props.data[date][0];
          tmp["newPatients"] += props.data[date][2];
          tmp["recurringPatients"] += props.data[date][1];
        } else if (
          moment(date, "DDMMYYYY").toDate() >= startDate &&
          moment(date, "DDMMYYYY").toDate() <= endDate
        ) {
          console.log(props.data)
          tmp2["totalVisits"] += props.data[date][0];
          tmp2["newPatients"] += props.data[date][2];
          tmp2["recurringPatients"] += props.data[date][1];
          tmp3[moment(date, "DDMMYYYY").toDate().toLocaleDateString().replaceAll("/", "-")] = props.data[date][0];
          console.log(date, moment(date, "DDMMYYYY").toDate().toLocaleDateString(), "metric")
        }
      }
      // console.log(date, tmp, tmp2, tmp3);
    });
    setAggregateData(tmp);
    setSelectedData(tmp2);
    setGraphData(tmp3);
  }, [startDate, endDate, props.data]);

  // console.log(startDate, endDate, graphData)




  // props.data.forEach((e) => {})
  return (
    <>
      {/* Content Header (Page header) */}
      <div className="content-header sticky-top" style={{ backgroundColor: "#f4f6f9" }}>
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <div style={{ display: "flex", }}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="nav-link"
                  data-widget="pushmenu"
                  role="button"
                  style={{
                    border: 0,
                    borderRadius: "18px",
                    // backgroundColor: "#f4f6f9",
                    color: "inherit"
                  }}
                >
                  <i className="fas fa-bars" />
                </a>
                <h1 className="m-0">Dashboard</h1>

              </div>
            </div>

            <div className="float-right d-none d-sm-inline-block" style={{ width: "50%", textAlign: "right" }} >

              <h3 style={{ margin: "0" }}>

                <IconButton sx={{ marginRight: "30px" }} onClick={() => { props.handleRemainderOpen() }} >
                  {!props.isRemainderOpen ?
                    <Badge badgeContent={props.todayReminderCount} color="error">
                      <Notifications color="primary" />
                    </Badge>
                    :
                    <NotificationsNoneOutlined />
                  }
                </IconButton>

                {props.userName}
              </h3>
            </div>
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      {/* /.content-header */}
      {/* Main content */}
      <section >
        <div className="container-fluid">
          {/* Small boxes (Stat box) */}
          <div className="row">
            <div className="col-lg-3 col-6">
              {/* small box */}
              <div className="small-box bg-success">
                <div className="inner">
                  <h3>{selectedData.totalVisits}</h3>
                  <p>Total Imaging Sessions</p>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars" />
                </div>
                <div className="small-box-footer">
                  {((
                    selectedData.totalVisits /
                    (selectedData.totalVisits + aggregateData.totalVisits)
                  ) * 100).toFixed(2)}
                  {"% "}
                  <i className="fas fa-arrow-circle-up" />
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                  <h3>
                    {selectedData.recurringPatients + selectedData.newPatients}
                  </h3>
                  <p>Total patients</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add" />
                </div>
                <div className="small-box-footer">
                  {((
                    (selectedData.recurringPatients +
                      selectedData.newPatients) /
                    (selectedData.recurringPatients +
                      selectedData.newPatients +
                      (aggregateData.recurringPatients +
                        aggregateData.newPatients))
                  ) * 100).toFixed(2)}
                  {"% "}
                  <i className="fas fa-arrow-circle-up" />
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              {/* small box */}
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3>{selectedData.recurringPatients}</h3>
                  <p>Patients with multiple visit</p>
                </div>
                <div className="icon">
                  <i className="ion ion-pie-graph" />
                </div>
                <div className="small-box-footer" style={{ color: "#000000" }}>
                  {((
                    selectedData.recurringPatients /
                    (selectedData.recurringPatients +
                      aggregateData.recurringPatients)
                  ) * 100).toFixed(2)}
                  {"% "}
                  <i className="fas fa-arrow-circle-up" />
                </div>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-3 col-6">
              {/* small box */}
              <div className="small-box bg-danger">
                <div className="inner">
                  <h3>{selectedData.newPatients}</h3>
                  <p>Patients with one visit</p>
                </div>
                <div className="icon">
                  <i className="ion ion-pie-graph" />
                </div>
                <div className="small-box-footer">
                  {((
                    selectedData.newPatients /
                    (selectedData.newPatients + aggregateData.newPatients)
                  ) * 100).toFixed(2)}
                  {"% "}
                  <i className="fas fa-arrow-circle-up" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              {/* small box */}
              <div
                className="small-box  bg-white"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="inner">
                  <h3
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {gramMetrics[0]}
                  </h3>
                  <p>No Growth</p>
                </div>
                <div className="icon"></div>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              {/* small box */}
              <div
                className="small-box bg-white"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="inner">
                  <h3
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {gramMetrics[2]}
                  </h3>
                  <p>Gram Positive</p>
                </div>
                <div className="icon"></div>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              {/* small box */}
              <div
                className="small-box  bg-white"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="inner">
                  <h3
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {gramMetrics[1]}
                  </h3>
                  <p>Gram Negative</p>
                </div>
                <div className="icon"></div>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              {/* small box */}
              <div
                className="small-box  bg-white"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="inner">
                  <h3
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {gramMetrics[3]}
                  </h3>
                  <p>Colonizing GN & GP</p>
                </div>
                <div className="icon">
                  {/* <i className="ion ion-stats-bars" /> */}
                </div>
              </div>
            </div>

            {/* ./col */}
          </div>
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <span>Choose date range</span>
          </div> */}
          <DatePickerComponent
            startDate={startDate}
            endDate={endDate}
            setStartDate={(v) => { setStartDate(v) }}
            setEndDate={v => {
              if (v > startDate) {
                setEndDate(v)
              }
            }}
          />
          <IlluminateActivityGraph data={graphData} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
        </div>
      </section>
    </>
  );
};

export default MacroMetricsCarousel;
