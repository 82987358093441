import React from "react";
import SplashView from "./SplashView";
import HomeScreen from "pages/Home";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import { ROUTES } from "navigation/contants";
import { selectAuthState, initApp } from "redux/authSlice";
import { Redirect } from "react-router";

export const SplashContainer = () => {
  const { user, isFetching } = useSelector(selectAuthState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initApp());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching) return <SplashView />;
  return user ? <HomeScreen /> : <Redirect to={ROUTES.LOGIN} />;
};
