import React from "react";
import styled from "styled-components";
import ImageComponent from "./ImageComponent";
import { useLayoutEffect, useState, useEffect } from "react";
import Plotly from "plotly.js-basic-dist-min";
import createPlotlyComponent from "react-plotly.js/factory";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Filler,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  defaults,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LinearScale,
  PointElement,
  ArcElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Plot = createPlotlyComponent(Plotly);
const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

const WoundDashView = (props) => {
  
  const areaList = [];
  const visitList = [];
  const gramList = [];
  const dates = [];
useEffect(()=>{
   if (Object.keys(props.woundMetrics).length !== 0 && !!props.woundMetrics[Object.keys(props.woundMetrics).length-1] && !props.woundMetrics[Object.keys(props.woundMetrics).length-1].woundArea ) alert("Please Update your Illuminate App or Contact software@adiuvodiagnostics.com")
},[props.woundMetrics])
 

  Object.keys(props.woundMetrics).forEach((e) => {
    if (e !== 0) {
      dates.push(props.woundMetrics[e].date);
      if (!props.woundMetrics[e].woundArea){
        // alert("Please Update your Illuminate App or Contact software@adiuvodiagnostics.com")
        areaList.push(props.woundMetrics[e].woundLength * props.woundMetrics[e].woundWidth)
      }else{
        if(props.woundMetrics[e].woundArea != -1){
          areaList.push(props.woundMetrics[e].woundArea);
         
        }else{
          areaList.push(props.woundMetrics[e].woundLength * props.woundMetrics[e].woundWidth)
        }      
      }

     
      visitList.push(e);
      
      gramList.push(props.woundMetrics[e].gramType);
    }
  });

  console.log(dates)
  const thumbs = {
    1 : props.wlURL
  }
  thumbs[visitList.at(-1)] = props.wllURL
  

  const createDataset = (xArr, yArr) => {
    let data = [];

    for (let i = 0; i < xArr.length; i++) {
      data.push({
        x: xArr[i],
        y: yArr[i],
      });
    }

    return data;
  };

  let lineXY = createDataset(visitList, areaList)

  const decodeDate = (date)=> `${date.split("")[6]}${date.split("")[7]}/${date.split("")[4]}${date.split("")[5]}/${date.substring(0,4)}`
  const decodeDate2 = (date)=> `${date.split("")[0]}${date.split("")[1]}/${date.split("")[2]}${date.split("")[3]}/${date.substring(4,8)}`

  const lineData = {
    labels: lineXY.map((a, i) => i + 1),
    datasets: [
      {
        label: `Wound Area `,
        data: lineXY,
        fill: true,
        backgroundColor: "rgba(95, 195, 246, 0.2)",
        borderColor: "rgba(95, 195, 246)",
        borderWidth: 2,
        tension: 0.4,
        pointHoverBorderColor: "white",
        pointHoverBackgroundColor: "#5fc3f6",
        pointBorderWidth: 3,
        pointHoverBorderWidth: 3,
        pointRadius: 6,
        pointHoverRadius: 6,
      },
    ],
  };

  const lineOptions = {
    maintainAspectRatio: false,
    responsive: true,

    plugins: {
      legend: {
        position: "top",
      },
      filler: {
        propagate: true,
      },
      tooltip: {
        // enabled: false,
        displayColors: false,
        // backgroundColor: "#000",
        intersect: true,
        yPadding: 6,
        xPadding: 6,
        caretSize: 4,
        bodyFontColor: "#5fc3f6",
        yAlign: "bottom",
        callbacks: {
          title: function (t) {
            console.log(t);
            return t[0].label;
          },
          label: function (t) {
            return `${t.dataset.label} : ${t.dataset.data[t.dataIndex].y}`;
          },
          afterBody: (t, d) => {
            console.log(t, d);

            return "Body";
          },
        },
        enabled: false,

        external: function (context) {
          // Tooltip Element
          let tooltipEl = document.getElementById("chartjs-tooltip");

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<table></table>";
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);

            let innerHtml = "<tbody><tr>";

            titleLines.forEach((title, i) => {
              if(!!props.urls)if (!!props.urls[title])  innerHtml += "<td><div class='d-flex justify-content-center align-items-center' style='height: 80px; overflow: hidden' ><img src='" + props.urls[title] + "' style='width:100px;' /></div></td>"
              innerHtml += "<td><p><b> Vist No. :"  + title + "</b></p>";
            });
            
            

            bodyLines.forEach(function ([body], i) {
              
              const colors = tooltipModel.labelColors[i];
              let style = "background:" + colors.backgroundColor;
              style += "; border-color:" + colors.borderColor;
              style += "; border-width: 2px";
              const span = '<span style="' + style + '"></span>';
              innerHtml +=  span + body.split(":")[0] + ": " + (+body.split(":")[1].split(" ").at(-1)).toFixed(2)  ;
              innerHtml += dates[i] ? "<p> date : " + decodeDate2(dates[i]) + "</p>" : ""
             
            });
            innerHtml += "</td></tr></tbody>";

            let tableRoot = tooltipEl.querySelector("table");
            tableRoot.innerHTML = innerHtml;
          }

          const position = context.chart.canvas.getBoundingClientRect();
          // const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = "absolute";
          tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX-75 + "px";
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY-95 + "px";
          // tooltipEl.style.font = bodyFont.string;
          tooltipEl.style.padding = "10px";
          tooltipEl.style.pointerEvents = "none";
          tooltipEl.style.backgroundColor = "rgba(255,255,255,0.4)"
          tooltipEl.style.borderRadius ="15px"
          tooltipEl.style.border = "solid 2px #5fc3f6"
          
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: Math.round( areaList.sort((a, b)=> a - b).at(-1) +5 )
      },
      x: {
        // max : +(visitList.sort((a, b)=> a - b).at(-1)) ,
        min: visitList.at(0),
              },
    },
  };

  const TooltipLines = {
    id: "tooltipLine",
    displayColor: false,
    yAlign: "bottom",
    beforeDraw: (chart) => {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const ctx = chart.ctx;
        ctx.save();
        const activePoint = chart.tooltip._active[0];
        // console.log(chart.chartArea);

        ctx.beginPath();
        ctx.setLineDash([5, 7]);
        ctx.moveTo(activePoint.element.x, activePoint.element.y);
        ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
        ctx.lineWidth = 2;
        ctx.strokeStyle = "#5fc3f6";
        ctx.stroke();
        ctx.restore();
      }
    },
  };


  return (
    <div className="tab-content">
      {/* <div
        className="tab-empty"
        style={{ height: 500, display: "felx", flexDirection: "column" }}
      >
        <span>{props.PID}</span>
        <span>{props.buffer}</span>
        <span>{props.wlURL}</span>

        <span>{JSON.stringify(props.woundData)}</span>
        <span>{JSON.stringify(props.woundMetrics)}</span>
      </div> */}
      <Container>
        <Content>
          <MetaDiv>
            <h1>{props.PID}</h1>
           { props.woundData && <Table>
              {Object.keys(props.woundData).map((e) => {
                const result = e.replace(/([A-Z])/g, " $1");
                const finalResult =
                  result.charAt(0).toUpperCase() + result.slice(1);
                return (
                  <TableRow key={e} >
                    <span>{finalResult}</span>
                    <span>{props.woundData[e]}</span>
                  </TableRow>
                );
              })}
            </Table>}
            {/* <span>Age: {props.woundData.age}</span>
            <span>Sex: {props.woundData.sex}</span>
            <span>Wound location: {props.woundData.woundLocation}</span> 
            <span>Wound type: {props.woundData.woundType}</span> */}
            {/* <div>
              <span>Status: </span>
              <span className="tag">Amputated</span>
            </div> */}
          </MetaDiv>
          <ImageDiv>
            <ImageComponent
              id={props.wlURL}
              url={props.wlURL}
              name={"First visit - White light"}
            />
            {props.showLImg && (
              <ImageComponent
                id={props.wllURL}
                url={props.wllURL}
                name={"Latest visit - White light"}
              />
            )}
          </ImageDiv>
          <GraphDiv>
            <div
              className="card"
              style={{
                position: "relative",
                left: "0px",
                top: "0px",
                width: "95%",
              }}
            >
              <div className="card-header ui-sortable-handle">
                <h3 className="card-title">
                  <i className="fas fa-chart-line mr-1" />
                  &ensp;Wound Area
                </h3>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="tab-content p-0">
                  {/* Morris chart - Sales */}
                  <div
                    className="chart tab-pane active"
                    id="revenue-chart"
                    style={{
                      position: "relative",
                      height: "300px",
                      // width: "100%",
                    }}
                  >
                    <Line 
                      data={lineData}
                      options={lineOptions}
                      plugins = {[TooltipLines]}
                      height={300}
                width={600}
                    />
                  </div>
                </div>
              </div>
              {/* /.card-body */}
            </div>
          </GraphDiv>
        </Content>
      </Container>
    </div>
  );
};

const Container = styled.div``;
const Content = styled.div`
  padding: 12px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: fr 1fr;
`;
const MetaDiv = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  grid-column: 1;
  grid-row: 1;
  /* background-color: red; */
  margin-top: 32px;
  span {
    font-size: 18px;
  }
  .tag {
    color: #ff0000;
  }
`;
const ImageDiv = styled.div`
  grid-gap: 10px;

  grid-column: 2;
  grid-row: 1;
  display: felx;
  justify-content: center;
  align-items: center;
  margin-top: 16px;

  /* background-color: yellow; */
`;
const GraphDiv = styled.div`
  grid-column: 1/3;
  grid-row: 2;
  /* background-color: black; */
  display: felx;
  justify-content: center;
  align-items: center;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
`;
const TableRow = styled.div`
  /* background-color: aqua; */
  padding: 9px 18px;
  margin-bottom: 6px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  span {
    :nth-child(2) {
      /* margin-left: 100px; */
    }
  }
  &:nth-of-type(odd) {
    background: #e0e0e0;
  }
`;

export default WoundDashView;
